<template>

    <base-card-plain>
            <template #header>
                <div class="col-3 col-md-3 col-lg-3 col-sm-12 mb-1">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Search .."
                         aria-describedby="button-addon2" />

                    </div>
                </div>
                <base-card-drop-down>
                    <template #title>
                        Options
                    </template>
                    <template #default>
                        <a class="dropdown-item" href="#">
                            <i class="bi bi-check-circle bs-icon-tiny" ></i>
                            Added
                        </a>
                        <a class="dropdown-item" href="#">
                            <i class="bi bi-circle bs-icon-tiny" ></i>
                            Testing
                        </a>
                        <a class="dropdown-item" href="#">
                            <i class="bi bi-circle bs-icon-tiny" ></i>
                            Ongoing
                        </a>
                        <a class="dropdown-item" href="#">
                            <i class="bi bi-circle bs-icon-tiny" ></i>
                            Pending
                        </a>
                        <a class="dropdown-item" href="#">
                            <i class="bi bi-circle bs-icon-tiny" ></i>
                            Rejected
                        </a>
                        <a class="dropdown-item" href="#">
                            <i class="bi bi-circle bs-icon-tiny" ></i>
                            Create new request
                        </a>
                    </template>
                </base-card-drop-down>
            </template>
            <template #default>
                <table class="table table-bordered table-responsive">
                    <thead>
                        <tr>
                            <th >Request</th>
                            <th class="qtr-td-col">Status</th>
                            <th class="tiny-td-col">Actions</th>
                        </tr>
                    </thead>
                    <tbody class="">
                        <tr class="table-hover">
                            <td>
                                <span class="fw-bold">
                                    Add onboarding feature
                                    <base-badge  badgeColor="secondary" :roundEdge="true" >+45</base-badge>
                                </span>
                            </td>
                            <td>
                                <base-badge  badgeColor="warning">pending</base-badge>
                            </td>
                            <td>
                                <base-card-drop-down>
                                    <template #title>
                                        <i class="bi bi-three-dots bs-icon-small" ></i>
                                    </template>
                                    <template #default>
                                        <router-link to="/recruitment_manage_applicants" class="dropdown-item">
                                            <i class="bi bi-person-badge-fill bs-icon-small" ></i>
                                            <span> View details </span>
                                        </router-link>
                                        <router-link to="/recruitment_manage_applicants" class="dropdown-item">
                                            <i class="bi bi-person-badge-fill bs-icon-small" ></i>
                                            <span> Vote </span>
                                        </router-link>
                                    </template>
                                </base-card-drop-down>
                            </td>
                        </tr>
                        <tr class="table-hover">
                            <td>
                                <span class="fw-bold">
                                    Add offboarding feature
                                    <base-badge  badgeColor="secondary" :roundEdge="true" >+145</base-badge>
                                </span>
                            </td>
                            <td>
                                <base-badge  badgeColor="info">Ongoing</base-badge>
                            </td>
                            <td>
                                <base-card-drop-down>
                                    <template #title>
                                        <i class="bi bi-three-dots bs-icon-small" ></i>
                                    </template>
                                    <template #default>
                                        <router-link to="/recruitment_manage_applicants" class="dropdown-item">
                                            <i class="bi bi-person-badge-fill bs-icon-small" ></i>
                                            <span> View details </span>
                                        </router-link>
                                        <router-link to="/recruitment_manage_applicants" class="dropdown-item">
                                            <i class="bi bi-person-badge-fill bs-icon-small" ></i>
                                            <span> Vote </span>
                                        </router-link>
                                    </template>
                                </base-card-drop-down>
                            </td>
                        </tr>
                        <tr class="table-hover">
                            <td>
                                <span class="fw-bold">
                                    Add PER feature
                                    <base-badge  badgeColor="secondary" :roundEdge="true" >+35</base-badge>
                                </span>
                            </td>
                            <td>
                                <base-badge  badgeColor="primary">Testing</base-badge>
                            </td>
                            <td>
                                <base-card-drop-down>
                                    <template #title>
                                        <i class="bi bi-three-dots bs-icon-small" ></i>
                                    </template>
                                    <template #default>
                                        <router-link to="/recruitment_manage_applicants" class="dropdown-item">
                                            <i class="bi bi-person-badge-fill bs-icon-small" ></i>
                                            <span> View details </span>
                                        </router-link>
                                        <router-link to="/recruitment_manage_applicants" class="dropdown-item">
                                            <i class="bi bi-person-badge-fill bs-icon-small" ></i>
                                            <span> Vote </span>
                                        </router-link>
                                    </template>
                                </base-card-drop-down>
                            </td>
                        </tr>
                        <tr class="table-hover">
                            <td>
                                <span class="fw-bold">
                                    Add Learning feature
                                    <base-badge  badgeColor="secondary" :roundEdge="true" >+45</base-badge>
                                </span>
                            </td>
                            <td>
                                <base-badge  badgeColor="success">added</base-badge>
                            </td>
                            <td>
                                <base-card-drop-down>
                                    <template #title>
                                        <i class="bi bi-three-dots bs-icon-small" ></i>
                                    </template>
                                    <template #default>
                                        <router-link to="/recruitment_manage_applicants" class="dropdown-item">
                                            <i class="bi bi-person-badge-fill bs-icon-small" ></i>
                                            <span> View details </span>
                                        </router-link>
                                    </template>
                                </base-card-drop-down>
                            </td>
                        </tr>
                        <tr class="table-hover">
                            <td>
                                <span class="fw-bold">
                                    Add leave balance feature
                                    <base-badge  badgeColor="secondary" :roundEdge="true" >+45</base-badge>
                                </span>
                            </td>
                            <td>
                                <base-badge  badgeColor="danger">rejected</base-badge>
                            </td>
                            <td>
                                <base-card-drop-down>
                                    <template #title>
                                        <i class="bi bi-three-dots bs-icon-small" ></i>
                                    </template>
                                    <template #default>
                                        <router-link to="/recruitment_manage_applicants" class="dropdown-item">
                                            <i class="bi bi-person-badge-fill bs-icon-small" ></i>
                                            <span> View details </span>
                                        </router-link>
                                    </template>
                                </base-card-drop-down>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <base-pagination>
                </base-pagination>

            </template>
    </base-card-plain>



</template>
